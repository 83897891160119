import React from "react"
import { Col, Row, Container, Image } from "react-bootstrap"
import "./style.scss"

const FeatureRow = ({
  title,
  description,
  image,
  illustration,
  index,
  top,
  left,
  imagePosition,
  illustrationPosition,
  features,
    color,
  ...props
}) => {
  const direction = index % 2 === 0
  const illustrationImageStyles = {
    borderTop: "0",
    borderBottom: `5px solid ${color}`,
    borderLeft: !direction ? `5px solid ${color}` : '0',
    borderRight: direction ? `5px solid ${color}` : '0',
  }
  return (
    <section
      style={{
        padding: "3em 0em",
        justifyContent: "center",
        backgroundColor: direction ? "" : "#f5f5f5",
      }}
    >
      <Container>
        <Row
          className="no-gutters mx-0 px-0 py-3 mx-0 d-flex flex-column-reverse flex-md-row"
          {...props}
          id="features"
        >
          <Col
            md={{ span: 6, order: direction ? 2 : 1 }}
            className={
              "text-md-" +
              (direction ? "left" : "right") +
              "  align-items-end align-self-center justify-content-center mx-width"
            }
          >
            <Container
              className={
                "p" + (direction ? "l" : "r") + "-lg-6 pt-md-1 ml-auto mr-auto"
              }
            >
              <div
                className={`${
                  illustration ? `p__relative` : ""
                } align-items-end mx-width`}
              >
                {illustration ? (
                  <>
                    <Image
                      src={image}
                      alt={`${title} image`}
                      className={`${
                        imagePosition === "top" ? "z__top" : "z__base"
                      } p__relative bg-transparent`}
                      fluid
                      thumbnail
                      height={600}
                      style={illustrationImageStyles}
                    />
                  </>
                ) : (
                  <Image
                    src={image}
                    alt={`${title} image`}
                    className="bg-transparent"
                    fluid
                    rounded
                    height={600}
                    style={illustrationImageStyles}
                  />
                )}
              </div>
            </Container>
          </Col>
          <Col
            md={{ span: 6, order: direction ? 1 : 2 }}
            className={`${
              illustration ? `p__relative` : ""
            } align-items-end mx-width my-auto`}
          >
            <Container>
              <Row className="mx-0 p-4">
                <p className="h3 text-left mb-4 p-0" style={{ color: color === '#1F7ABF' ? '#1F7ABF' : 'initial'}}>
                  {title}
                </p>
                <p
                  className="text-left fw-medium description"
                  style={{ fontSize: "1rem", color: "#6e6e6e", borderColor: color }}
                >
                  {description}
                </p>
                <Row className="w-75">
                  {features?.map((feature, index) => (
                    <Col
                      key={index}
                      className="d-flex flex-column justify-content-center align-items-center px-0 py-2"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                    >
                      <div>
                        <Image
                          src={feature.image}
                          style={{ width: "2.5rem" }}
                        />
                      </div>
                      <p
                        className="text-center mt-3 fw-bold"
                        style={{ fontSize: "0.7rem", color: "#6e6e6e" }}
                      >
                        {feature.description}
                      </p>
                    </Col>
                  ))}
                </Row>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FeatureRow
