import React from "react"
import { Row, Container, Col, Image } from "react-bootstrap"
import Form from "../../static/images/Form.svg"
import Label from "../../static/images/Label.svg"
import FormsImg from "../../static/images/forms.png"
import Applications from "../../static/images/Icon_application.svg"

const Addons = () => {
  return (
    <section
      className="p-5"
      style={{
        backgroundColor: "#FFCB27",
      }}
    >
      <Container>
        <div className="h2 d-flex w-100 justify-content-center mb-5">
          Addons
        </div>
        <Row className="justify-content-center">
          <Col className="d-flex flex-column justify-content-center">
            <Row className="d-flex align-items-center justify-content-center">
              <Col xs="12" sm="3" className="w-auto mb-3 mb-md-0">
                <Image src={Form} />
              </Col>
              <Col>
                <div className="h4 fw-bold">Form Builder </div>
                <p className="mt-2 h6">
                  Create digital forms just the way you want them to be with our
                  easy to use with advanced tools.
                </p>
              </Col>
            </Row>
            <Row className="mt-5 d-flex align-items-center justify-content-center">
              <Col xs="12" sm="3" className="w-auto mb-3 mb-md-0">
                <Image src={Applications} />
              </Col>
              <Col>
                <div className="h4 fw-bold">Program Application</div>
                <p className="mt-2 h6">
                  Automate your program application using our Form Builder.
                </p>
              </Col>
            </Row>
            <Row className="mt-5 d-flex align-items-center justify-content-center">
              <Col xs="12" sm="3" className="w-auto mb-3 mb-md-0">
                <Image src={Label} />
              </Col>
              <Col>
                <div className="h4 fw-bold">White Label</div>
                <p className="mt-2 h6">
                  Name your platform after your own Brand to position your
                  innovation project to the next level.
                </p>
              </Col>
            </Row>
          </Col>
          <Col>
            <Image src={FormsImg} fluid />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Addons
