import React, { useContext } from "react"
import {Row, Button, Container, Col } from "react-bootstrap"
import { ModalContext } from "../layout"
import "./styles.scss"

const Banner = ({ buttonText = '', text = '', isSupportPage = false, onClickSubmitTicket  }) => {
  const [, setShowModal] = useContext(ModalContext)
  const handleShow = () => setShowModal(true)

  if (isSupportPage) {
    return (
        <Container className="root" fluid={true}>
          <Row>
            <Col xs={12} md={10} lg={10} className="mx-auto">
              <Row >
                <Col xs={12} md={4} className="d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                  <Button
                      className="text-nowrap banner-styled-buttons"
                      onClick={() => {
                          onClickSubmitTicket && onClickSubmitTicket()
                          handleShow()
                      }}
                  >
                    Submit a Ticket
                  </Button>
                </Col>
                <Col xs={12} md={4} className="my-4 my-md-0 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                    <Button className="text-nowrap banner-styled-buttons" onClick={() => window.open('mailto:info@teamcoach.es', '_blank')}>
                      Email Us
                    </Button>
                </Col>
                <Col xs={12} md={4} className="d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                  <Button className="text-nowrap banner-styled-buttons" onClick={handleShow}>
                    Meeting Agenda
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
    )
  }

  return (
      <>
        <Container className="d-flex justify-content-center root">
          <Row className="mx-0">
            <div className="d-flex flex-column d-md-inline-flex flex-md-row align-items-center justify-content-center">
              <div className="h3 mb-3 mb-md-0 banner-text fw-bold text-center px-4">
                {" "}
                {text}{" "}
              </div>

              <div>
                <Button className="text-nowrap" onClick={handleShow}>
                  {buttonText}
                </Button>
              </div>
            </div>
          </Row>
        </Container>
      </>
  );
}

export default Banner
