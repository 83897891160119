import React from "react"
import { Container } from "react-bootstrap"
import ProductHeroIllustration from "../../static/images/Product_hero.webp"
import Cta from "../components/cta"
import Footer from "../components/footer"
import Layout from "../components/layout"
import NavBar from "../components/navBar"
import FeatureRow from "../components/featureRow"
import Seo from "../components/seo"
import Banner from "../components/banner"
import {
  COMMUNITY_FEATURES,
  EDUCATION_FEATURES,
  MENTORING_FEATURES,
  STARTUP_FEATURES,
} from "../_shared/constants"
import Addons from "../components/Addons"
import mentoringModuleGif from "../../static/images/mentoring.gif"
import startupModuleGif from "../../static/images/startup.gif"
import communityModuleGif from "../../static/images/community.gif"
import educationModuleGif from "../../static/images/education.gif"
import NewHero from "../components/hero/newHero";

const Product = ({ location }) => {
  return (
    <Layout>
      <Seo title="Team Coaches" />
      <NavBar location={location} />
      <Container>
        <div className="my-5">
          <NewHero
            title={
              <>
                Innovation <br /> Intelligence <br /> CRM
              </>
            }
            description="Skyrocket your innovation project results with our specialized modules equipped with all the tools you need and personalize them to your own innovation requirements."
            altText="Let's Talk"
            buttonText="Let's Talk"
            image={ProductHeroIllustration}
            style={{
              justifyContent: "space-between !important",
            }}
          />
        </div>
        <Banner
          buttonText="Get a Demo"
          text="Discover Team Coaches IRM Software"
        />
        <FeatureRow
          title="Community Module"
          description="All the tools to manage and have a better interaction with your community. Share important resources with your teams and easily manage your CRM activities creating a friendly environment to develop results and a more responsive community. "
          image={communityModuleGif}
          illustration={communityModuleGif}
          index={2}
          color="#1F7ABF"
          top="-5rem"
          left="-2.4rem"
          imagePosition="top"
          illustrationPosition="bottom"
          features={COMMUNITY_FEATURES}
        />
        <FeatureRow
          title={
            <>
              Startup Growth <br />{" "}
              <span className="fw-bold" style={{ color: "#6e6e6e" }}>
                {" "}
                Portfolio Module
              </span>
            </>
          }
          description="Interact and manage your companies with easy to use and effective tools to assign tasks to teams, manage files, measure results through metrics and automatic reports.          "
          image={startupModuleGif}
          illustration={startupModuleGif}
          index={1}
          color="#EE283D"
          top="-6.9rem"
          left="-5.3em"
          imagePosition="bottom"
          illustrationPosition="top"
          features={STARTUP_FEATURES}
        />
        <FeatureRow
          title="Education Module"
          description="Keep your community and innovation teams actualized with our education tools for creating personalized courses just for the needs of your teams. "
          image={educationModuleGif}
          illustration={educationModuleGif}
          index={2}
          color="#FFCB27"
          top="-3rem"
          left="-0.5rem"
          imagePosition="bottom"
          illustrationPosition="top"
          features={EDUCATION_FEATURES}
        />
        <FeatureRow
          title="Mentoring"
          description="Push your community to the next level with tools for one on one meetings, schedule events, assign tasks to teams and more to transform your entrepreneur teams and innovation projects into a sustainable business reality."
          image={mentoringModuleGif}
          index={1}
          color="#1F7ABF"
          top="-6.9rem"
          left="-5.3em"
          imagePosition="bottom"
          illustrationPosition="top"
          features={MENTORING_FEATURES}
        />
        <Addons />
        <Cta
          backgroundColor="back-blue"
          buttonColor="blue"
          title="Interested in Learning more?"
          buttonText="Let's Talk"
        />
        <Footer />
      </Container>
    </Layout>
  )
}

export default Product
